export const wsGetOffset = (elem) => {
  try {
    const box = elem.getBoundingClientRect();
    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.scrollX || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return {top: top, left: left};
  } catch (e) {
    return {top: null, left: null};
  }
};

export const wsIsElement = (obj) => {
  try {
    return !!obj.constructor.__proto__.prototype.constructor.name;
  } catch (e) {
    return false;
  }
};

export const wsLoadScript = (file_url, async = true, type = 'text/javascript') => {
  return new Promise((resolve, reject) => {
    try {
      let scriptEl = document.querySelector(`script[src="${file_url}"]`);

      if (scriptEl) {
        return resolve({status: true});
      }

      scriptEl = document.createElement('script');
      scriptEl.type = type;
      scriptEl.async = async;
      scriptEl.src = file_url;

      scriptEl.addEventListener('load', () => {
        resolve({status: true});
      });

      scriptEl.addEventListener('error', () => {
        reject({
          status: false,
          message: `Failed to load the script ＄{file_url}`,
        });
      });

      document.body.appendChild(scriptEl);
    } catch (err) {
      reject(err);
    }
  });
};
