export const initAccordions = () => {
  const togglers = document.querySelectorAll('[data-ws-accordion]');

  if (togglers) {
    togglers.forEach((toggler) => {
      toggler.setAttribute('data-ws-accordion', 'inactive');
      const buttons = Array.from(toggler.querySelectorAll(':scope [data-ws-accordion-btn]'));

      const buttonsInnerAnotherAccordion = Array.from(
        toggler.querySelectorAll(':scope [data-ws-accordion] [data-ws-accordion-btn]'),
      );

      buttonsInnerAnotherAccordion.forEach((btnInnerAnotherAccordion) => {
        const index = buttons.indexOf(btnInnerAnotherAccordion);
        if (index > -1) {
          buttons.splice(index, 1);
        }
      });

      buttons?.forEach((btn) => {
        btn.addEventListener(
          'click',
          (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            toggler.setAttribute(
              'data-ws-accordion',
              toggler.getAttribute('data-ws-accordion') === 'active' ? 'inactive' : 'active',
            );
          },
          true,
        );
      });
    });
  }
};
