const initOurClients = async () => {
  const sections = Array.from(document.getElementsByClassName('b-our-clients'));

  for (const section of sections) {
    const speed = 0.25;
    const parentSelector = section.querySelector('.b-our-clients__slider');

    if (!parentSelector) {
      return;
    }

    const clone = parentSelector.innerHTML;
    const firstElement = parentSelector.children[0];
    let i = 0;

    parentSelector.insertAdjacentHTML('beforeend', clone);
    parentSelector.insertAdjacentHTML('beforeend', clone);

    const wrappers = Array.from(parentSelector.children);
    setInterval(function () {
      wrappers.forEach((wrapper) => {
        wrapper.style.transform = `translate3d(-${i}px,0,0)`;
      });
      if (i > firstElement.clientWidth) {
        i = 0;
      }
      i = i + speed;
    }, 0);
  }
};

export default initOurClients;
