export class postSidebarNav {
  constructor(args) {
    this.el = args.el;
    this.observer = this.createIntersectionObserver();

    this.initNav();
  }

  createIntersectionObserver() {
    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetId = entry.target.id;
            const navItem = this.el.querySelector(`.post-sidebar-nav__link[href="#${targetId}"]`).parentNode;
            navItem.classList.add('active');
          } else {
            const targetId = entry.target.closest('section').id;
            const navItem = this.el.querySelector(`.post-sidebar-nav__link[href="#${targetId}"]`).parentNode;
            navItem.classList.remove('active');
          }
        });
      },
      {
        rootMargin: '0px 0px -25% 0px',
        threshold: 0.1,
      },
    );
  }

  initNav() {
    const domTargets = document.querySelectorAll('.b-post-content-row__heading');
    const navList = this.el.querySelector('.post-sidebar-nav__list');

    domTargets.forEach((target) => {
      this.observer.observe(target.closest('section'));

      const itemId = target.closest('section').id;
      const navItem = document.createElement('li');

      navItem.classList.add('post-sidebar-nav__item');
      navItem.innerHTML = `<a href="#${itemId}" class="post-sidebar-nav__link">${target.textContent}</a>`;

      navItem.addEventListener('click', (e) => {
        e.preventDefault();
        const target = document.querySelector(`#${itemId}`);
        target.scrollIntoView({behavior: 'smooth', block: 'start'});
      });

      navList.appendChild(navItem);
    });
  }
}
