import {wsGetOffset} from '@scripts/app/helpers';

/*
 * Smooth Scroll Helper
 */

export const wsSmoothScrollToEl = (element, offset = -144) => {
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: wsGetOffset(element).top + offset,
    });
  } else {
    window.scrollTo(0, wsGetOffset(element).top + offset);
  }
};

/*
 * Viewport Helper for elements sizing based on viewport units
 */

export const wsUpdateViewportSize = () => {
  const wsUpdateViewportProperties = () => {
    document.documentElement.style.setProperty('--ws-viewport-w', document.documentElement.clientWidth + 'px');
    document.documentElement.style.setProperty('--ws-viewport-h', window.innerHeight + 'px');
  };

  wsUpdateViewportProperties();

  if (typeof window.screen.orientation === 'undefined' || matchMedia('(pointer: fine)').matches) {
    window.addEventListener('resize', wsUpdateViewportProperties);
  } else {
    let mql = matchMedia('(orientation: portrait)');
    mql.addEventListener('change', () => {
      wsUpdateViewportProperties();
    });
  }
};

/*
 * Fluid Helper for responsive elements
 */

const wsUpdateFluid = (container, element) => {
  let containerOffsetLeft = wsGetOffset(container).left;
  let containerOffsetRight = containerOffsetLeft + container.getBoundingClientRect().width;

  const offsetLeftLeft = wsGetOffset(element).left - containerOffsetLeft;
  const offsetLeftRight = wsGetOffset(element).left + element.getBoundingClientRect().width - containerOffsetLeft;
  const offsetRightLeft = containerOffsetRight - wsGetOffset(element).left;
  const offsetRightRight = containerOffsetRight - wsGetOffset(element).left - element.getBoundingClientRect().width;

  element.style.setProperty('--ws-fluid-content-ll', Math.abs(offsetLeftLeft) + 'px');
  element.style.setProperty('--ws-fluid-content-lr', Math.abs(offsetLeftRight) + 'px');
  element.style.setProperty('--ws-fluid-content-rl', Math.abs(offsetRightLeft) + 'px');
  element.style.setProperty('--ws-fluid-content-rr', Math.abs(offsetRightRight) + 'px');
};

export const wsAddFluid = (element) => {
  if (element.wnFluidContentInitialized === true) return;

  const container =
    element.closest('[data-ws-fluid-container]') || document.getElementById('main-view') || document.body;

  element.wnFluidContentInitialized = true;

  const resizeObserver = new ResizeObserver(() => {
    wsUpdateFluid(container, element);
  });

  resizeObserver.observe(element);
  window.addEventListener('resize', () => wsUpdateFluid(container, element));
};

export const wsInitFluids = () => {
  const fluidContents = document.querySelectorAll('[data-ws-fluid-content]');

  if (fluidContents) {
    fluidContents.forEach((element) => {
      wsAddFluid(element);
    });
  }
};
