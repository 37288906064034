import Lenis from 'lenis';
import throttle from 'lodash.throttle';

export const initAnimations = async (headerClass = null) => {
  const {gsap} = await import('gsap');
  const {ScrollTrigger} = await import('gsap/ScrollTrigger');

  gsap.registerPlugin(ScrollTrigger);

  const lenisScroller = new Lenis({
    duration: 1.4,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    smoothTouch: false,
  });

  window.lenisScroller = lenisScroller;

  lenisScroller.on('scroll', ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenisScroller.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  const scrollToAnchor = (e) => {
    e.preventDefault();

    const slug = e.currentTarget.getAttribute('href').split('#')[1];
    if (!slug) return;

    const target = document.getElementById(slug);
    if (!target) return;

    if (target.getAttribute('role') === 'dialog') {
      target.setAttribute('aria-hidden', 'false');
    } else {
      const offset = -parseInt(getComputedStyle(document.documentElement).getPropertyValue('scroll-padding-top'));
      lenisScroller.scrollTo(target, {
        offset: isNaN(offset) ? 0 : offset,
      });
    }

    headerClass?.hide();
  };

  const isAnchorOnTheSamePage = (anchor) => {
    return anchor?.href?.split('#')[0] === window?.location?.href?.split('#')[0];
  };

  const scrollToNextSection = (currentSection, e) => {
    e.preventDefault();
    const nextSection = currentSection.nextElementSibling;
    if (nextSection) {
      lenisScroller.scrollTo(nextSection, {
        offset: -parseInt(getComputedStyle(document.documentElement).getPropertyValue('scroll-padding-top')),
      });
    }
  };

  const checkAnchors = () => {
    const anchors = document.querySelectorAll('a[href*="#"]:not([role="tab"])');

    anchors.forEach((anchor) => {
      const href = anchor.getAttribute('href');
      if (href !== '#') {
        if (!isAnchorOnTheSamePage(anchor)) return;
        anchor.removeEventListener('click', scrollToAnchor, true);
        anchor.addEventListener('click', scrollToAnchor, true);
      } else {
        const section = anchor.closest('[data-block-id]');
        if (section) {
          anchor.removeEventListener('click', scrollToNextSection, true);
          anchor.addEventListener('click', scrollToNextSection.bind(this, section), false);
        }
      }
    });
  };

  const btnsScrollToNext = () => {
    const btns = document.querySelectorAll('[data-scroll-down]');
    btns.forEach((btn) => {
      const currentSection = btn.closest('[data-block-id]');
      btn.addEventListener('click', scrollToNextSection.bind(this, currentSection), true);
    });
  };

  btnsScrollToNext();

  const throttledCheckElements = throttle(checkAnchors, 1000);

  new MutationObserver(throttledCheckElements).observe(document.body, {
    childList: true,
    subtree: true,
    attributes: true,
  });

  const mainView = document.getElementById('main-view');

  let globalResizeObserverTimeout = null;
  const globalResizeObserver = new ResizeObserver(() => {
    clearTimeout(globalResizeObserverTimeout);
    globalResizeObserverTimeout = setTimeout(() => {
      ScrollTrigger.refresh(window.innerWidth < 1024);
    }, 50);
  });

  globalResizeObserver.observe(mainView ?? document.body);

  const {initBasicAnimations} = await import('./animations/basic.js');

  initBasicAnimations();
};
