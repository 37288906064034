const stickyCtaAnim = () => {
  const container = document.querySelectorAll('[data-sticky-cta-anim]');

  container.forEach((cta) => {
    const bg = cta.querySelectorAll('[data-sticky-cta-bg]');
    const textColor = cta.querySelectorAll('[data-sticky-cta-text-color]');
    const iconColor = cta.querySelectorAll('.cta-icon path');
    const mainContent = document.querySelector('main.main-content');
    const firstSection = mainContent.querySelector('section');

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          bg.forEach((item) => {
            item.style.backgroundColor = '#28272d';
          });
          textColor.forEach((item) => {
            item.style.color = '#FFFFFF';
          });
          iconColor.forEach((item) => {
            item.style.fill = '#28272d';
          });
        } else {
          bg.forEach((item) => {
            item.style.backgroundColor = '#FFE4E1';
          });
          textColor.forEach((item) => {
            item.style.color = '#FF533D';
          });
          iconColor.forEach((item) => {
            item.style.fill = '#FFE4E1';
          });
        }
      });
    }, options);

    observer.observe(firstSection);
  });
};

export default stickyCtaAnim;
