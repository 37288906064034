const videoLazyInit = () => {
  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const videoObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const video = entry.target;
        const videoSrc = video.getAttribute('data-src');
        if (videoSrc) {
          video.src = videoSrc;
        }

        const sources = video.querySelectorAll('source');

        if (sources.length) {
          for (const source of sources) {
            const sourceSrc = source.getAttribute('data-src');
            if (sourceSrc) {
              source.src = sourceSrc;
            }
          }
        }

        video.load();

        // Handle the video play logic
        if (video.getAttribute('data-video-part') === '1') {
          video.addEventListener('timeupdate', function () {
            const remainingTime = video.duration - video.currentTime;
            if (remainingTime <= 9) {
              const nextVideo = document.querySelector('[data-video-part="2"]');
              if (nextVideo && nextVideo.paused) {
                nextVideo.classList.remove('hidden');
                nextVideo.play();
              }
            }
          });
          video.addEventListener('ended', function () {
            video.classList.add('hidden');
          });
        }

        observer.unobserve(video);
      }
    });
  }, observerOptions);

  const videos = document.querySelectorAll('.video-lazy video');
  videos.forEach((video) => {
    videoObserver.observe(video);
  });
};

export default videoLazyInit;
