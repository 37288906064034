import {wsIsElement, wsLoadScript} from '@scripts/app/helpers';
import axios from 'axios';

export const wsLoadRecaptcha = () => {
  return new Promise((resolve, reject) => {
    try {
      if (typeof grecaptcha === 'object') {
        return resolve({status: true});
      } else {
        wsLoadScript('https://www.google.com/recaptcha/api.js?render=' + window.wsConfig.gRecaptchaSiteKey, false)
          .then(() => {
            resolve({status: true});
          })
          .catch((err) => {
            reject(err);
          });
      }
    } catch (err) {
      reject(err);
    }
  });
};

export class wsRecaptchaForms {
  constructor() {
    const self = this;
    self.addScript = self.addScript.bind(this);
    self.addEvents = self.addEvents.bind(this);
    self.removeEvents = self.removeEvents.bind(this);
    if (
      typeof window.wsConfig.gRecaptchaSiteKey === 'string' &&
      window.wsConfig.gRecaptchaSiteKey.length &&
      typeof grecaptcha !== 'object'
    ) {
      self.forms = document.querySelectorAll('[data-ws-form]') ?? [];
      self.addEvents();
    }
  }

  addScript() {
    const self = this;
    if (
      typeof window.wsConfig.gRecaptchaSiteKey === 'string' &&
      window.wsConfig.gRecaptchaSiteKey.length &&
      typeof grecaptcha !== 'object'
    ) {
      self.removeEvents();
      wsLoadRecaptcha();
    }
  }

  addEvents() {
    const self = this;
    self.forms.forEach((form) => {
      form.addEventListener('click', self.addScript);
      form.addEventListener('focusin', self.addScript);
      form.addEventListener('mouseenter', self.addScript);
    });
  }

  removeEvents() {
    const self = this;
    self.forms.forEach((form) => {
      form.removeEventListener('click', self.addScript);
      form.removeEventListener('focusin', self.addScript);
      form.removeEventListener('mouseenter', self.addScript);
    });
  }
}

export class wsForm {
  constructor(args) {
    const self = this;

    self.onSubmit = self.onSubmit.bind(this);

    self.config = Object.assign(
      {
        el: null,
        action: '',
        responseEl: null,
        submitBtnEl: null,
        submitBtnTextEl: null,
        submitBtnTextDefault: '',
        submitBtnTextSending: '',
      },
      args,
    );

    if (!wsIsElement(args.el)) {
      return console.warn('Set el attribute.');
    }

    if (typeof self.config.action !== 'string' || self.config.action.length === 0) {
      self.config.action = self.config.el.getAttribute('data-ws-form');
      if (!self.config.action) {
        return console.warn('Set action in data-ws-form attribute.');
      }
    }

    self.initResponseBox();
    self.initSubmitButton();

    self.config.el.addEventListener('submit', self.onSubmit);

    return self;
  }

  initSubmitButton() {
    const self = this;
    self.config.submitBtnEl = self.config.el.querySelector('button[type="submit"]');
    self.config.submitBtnTextEl = self.config.el.querySelector('[data-ws-form-sending-text]');
    if (self.config.submitBtnTextEl) {
      self.config.submitBtnTextDefault = self.config.submitBtnTextEl.textContent;
      self.config.submitBtnTextSending = self.config.submitBtnTextEl.getAttribute('data-ws-form-sending-text');
    }
  }

  updateSubmitButton(status = '', disable = false) {
    const self = this;
    if (!self.config.submitBtnEl) return;

    if (disable) {
      self.config.submitBtnEl.setAttribute('disabled', true);
    } else {
      self.config.submitBtnEl.removeAttribute('disabled');
    }

    if (self.config.submitBtnTextEl && self.config.submitBtnTextDefault && self.config.submitBtnTextSending) {
      switch (status) {
        case 'sending':
          self.config.submitBtnEl.classList.add('is-sending');
          self.config.submitBtnTextEl.textContent = self.config.submitBtnTextSending;
          break;
        default:
          self.config.submitBtnEl.classList.remove('is-sending');
          self.config.submitBtnTextEl.textContent = self.config.submitBtnTextDefault;
      }
    }
  }

  initResponseBox() {
    const self = this;
    let el = self.config.el.querySelector('[data-ws-form-response]');
    if (!el) {
      el = document.createElement('div');
      el.setAttribute('data-ws-form-response', '');
      el.setAttribute('aria-hidden', 'true');
      self.config.el.appendChild(el);
    }
    return (self.config.responseEl = el);
  }

  updateResponseBox(status = 'info') {
    const self = this;
    self.config.responseEl.setAttribute('aria-hidden', 'false');
    self.config.responseEl.classList.remove('is-error', 'is-success');
    switch (status) {
      case 'error':
        self.config.responseEl.classList.add('is-error');
        break;
      case 'success':
        self.config.responseEl.classList.add('is-success');
        break;
    }
  }

  hideResponseBox() {
    const self = this;
    self.config.responseEl.setAttribute('aria-hidden', 'true');
  }

  onSubmit(e) {
    const self = this;
    e.preventDefault();
    self.updateSubmitButton('sending', true);
    if (typeof grecaptcha === 'object' && window.wsConfig.gRecaptchaSiteKey) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(window.wsConfig.gRecaptchaSiteKey, {action: 'submit'}).then((token) => {
          self.send(token);
        });
      });
    } else {
      self.send();
    }
  }

  send(recaptchaToken = '') {
    const self = this;
    const formData = new FormData(self.config.el);

    formData.append('action', self.config.action);
    formData.append('recaptcha_token', recaptchaToken);

    self.hideResponseBox();

    while (self.config.responseEl.firstChild) {
      self.config.responseEl.removeChild(self.config.responseEl.lastChild);
    }

    axios
      .post(window.wsConfig.ajaxUrl, formData, {
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.message) {
          const msg = document.createElement('p');
          msg.insertAdjacentHTML('afterbegin', response.data.message);
          self.config.responseEl.appendChild(msg);
        }

        if (response.data.status === 'success') {
          self.updateResponseBox('success');
          self.config.el.reset();
        } else if (response.data.errors) {
          const list = document.createElement('ul');
          for (let text of Object.values(response.data.errors)) {
            const listItem = document.createElement('li');
            listItem.insertAdjacentHTML('afterbegin', text);
            list.appendChild(listItem);
          }
          self.config.responseEl.appendChild(list);
          self.updateResponseBox('error');
        } else {
          const msg = document.createElement('p');
          msg.insertAdjacentHTML('afterbegin', 'An unexpected error has occurred.');
          self.config.responseEl.appendChild(msg);
          self.updateResponseBox('error');
        }

        self.updateSubmitButton();
      })
      .catch((error) => {
        console.log(error);
        const msg = document.createElement('p');
        msg.insertAdjacentHTML('afterbegin', 'An unexpected error has occurred.');
        self.config.responseEl.appendChild(msg);
        self.updateResponseBox('error');
        self.updateSubmitButton();
      });
  }
}

export const initFileFields = () => {
  const fields = document.querySelectorAll('.wpcf7-field-file');

  for (const field of fields) {
    const input = field.querySelector('input[type="file"]');
    const inputPlaceholder =
      field.querySelector('.wpcf7-field-file-input span') || field.querySelector('.wpcf7-field-file-input');
    const placeholderText = inputPlaceholder?.textContent;

    if (!input || !inputPlaceholder) {
      return;
    }

    const mutation = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          if (mutation.target.classList.contains('wpcf7-not-valid')) {
            inputPlaceholder.classList.add('wpcf7-not-valid');
          } else {
            inputPlaceholder.classList.remove('wpcf7-not-valid');
          }
        }
      });
    });

    mutation.observe(input, {attributes: true});

    input.addEventListener('change', () => {
      inputPlaceholder.textContent = input.files[0]?.name ?? placeholderText;
    });
  }
};
