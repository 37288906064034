import domReady from '@roots/sage/client/dom-ready';
import {wsHeader} from '@scripts/app/header';
import {wsInitFluids} from '@scripts/app/framework';
import {initAccordions} from '@scripts/app/accordion.js';
// import {wsRecaptchaForms, wsForm} from '@scripts/app/forms';
import {postSidebarNav} from '@scripts/app/post-sidebar-nav';
import initOurClients from '@scripts/app/our-clients';
import {initAnimations} from '@scripts/app/animations.js';
import bottomLinksAnim from '@scripts/app/animations/bottom-links';
import stickyCtaAnim from '@scripts/app/animations/sticky-cta';
import videoLazyInit from '@scripts/app/video-lazy';
import {initFileFields} from '@scripts/app/forms.js';

domReady(async () => {
  const header = document.querySelector('.c-hd');
  let headerClass = null;
  if (header) {
    headerClass = new wsHeader({
      el: header,
      buttons: header.querySelectorAll('[data-ws-header-toggle]'),
    });
  }

  const postSidebar = document.querySelector('.post-sidebar-nav');
  if (postSidebar) {
    new postSidebarNav({
      el: postSidebar,
    });
  }

  setScreenWidth();
  setScreenHeight();
  setScreenWidth();
  initAccordions();
  wsInitFluids();
  detectMenuItemAction();
  await initOurClients();
  await initAnimations(headerClass);
  bottomLinksAnim();
  stickyCtaAnim();
  videoLazyInit();
  initFileFields();

  window.addEventListener('resize', () => {
    setScreenWidth();
    setScreenHeight();
  });

  const scrollToNextSectionBtns = document.querySelectorAll('[data-scroll-to-next-section]');

  scrollToNextSectionBtns?.forEach((btn) => {
    btn.addEventListener('click', () => {
      const nextSection = btn.closest('section').nextElementSibling;
      nextSection.scrollIntoView({behavior: 'smooth', block: 'start'});
    });
  });

  const footer = document.querySelector('.c-footer');
  const ctaFixed = document.querySelector('[data-cta-fixed]');
  if (footer && ctaFixed) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const ctaFixedIcon = ctaFixed.querySelector('.cta-icon');
        const ctaFixedContainer = ctaFixed.querySelector('.cta-container');
        const windowWidth = window.innerWidth;
        const isDesktop = windowWidth >= 1024;

        if (entry.isIntersecting && isDesktop) {
          ctaFixedIcon.style.opacity = 0;
          ctaFixedContainer.style.opacity = 0;
        } else {
          ctaFixedIcon.style.opacity = 1;
          ctaFixedContainer.style.opacity = 1;
        }
      });
    });
    observer.observe(footer);
  }

  // new wsRecaptchaForms();
  // const forms = document.querySelectorAll('[data-ws-form]');
  // if (forms) {
  //   for (let form of forms) {
  //     new wsForm({
  //       el: form,
  //     });
  //   }
  // }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

function setScreenHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function setScreenWidth() {
  const scrollbarWidth = (window.innerWidth - document.documentElement.clientWidth) * 0.01;
  const vw = window.innerWidth * 0.01 - scrollbarWidth;
  document.documentElement.style.setProperty('--vw', `${vw}px`);
}

function detectMenuItemAction() {
  const menuItems = document.querySelectorAll('.menu-item.large-submenu');
  const html = document.documentElement;
  html.setAttribute('data-mega-menu', 'inactive');

  for (let menuItem of menuItems) {
    // hover & focus
    menuItem.addEventListener('mouseenter', () => {
      html.setAttribute('data-mega-menu', 'active');
    });

    menuItem.addEventListener('mouseleave', () => {
      html.setAttribute('data-mega-menu', 'inactive');
    });

    menuItem.addEventListener('focus', () => {
      html.setAttribute('data-mega-menu', 'active');
    });

    menuItem.addEventListener('blur', () => {
      html.setAttribute('data-mega-menu', 'inactive');
    });
  }
}
