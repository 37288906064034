import {wsIsElement} from '@scripts/app/helpers';

export class wsHeader {
  constructor(args) {
    const self = this;

    self.config = Object.assign(
      {
        el: null,
        buttons: null,
        buttonClassActive: 'is-active',
        dataAttrName: 'header',
        inertElements: null,
        breakpointWidth: '1024px',
      },
      args,
    );

    if (self.config.buttons !== null) {
      self.config.buttons = Array.from(self.config.buttons);
    }

    if (!wsIsElement(args.el)) {
      return console.warn('Set el attribute with header element.');
    }

    if (typeof document.documentElement.dataset[self.config.dataAttrName] === 'undefined') {
      document.documentElement.dataset[self.config.dataAttrName] = 'inactive';
    }

    this.initButtons();
    this.initDesktopDropdowns();
    this.initMobileDropdowns();
    this.initDesktopSubmenus();

    self.updateScroll();
    window.addEventListener('scroll', self.updateScroll);

    self.initInertElements();

    return self;
  }

  updateScroll() {
    if (window.scrollY >= 24) {
      document.documentElement.dataset.scrolled = 'true';
    } else {
      delete document.documentElement.dataset.scrolled;
    }
  }

  show() {
    const self = this;
    document.documentElement.dataset[self.config.dataAttrName] = 'active';
    if (self.config.buttons) {
      self.config.buttons.forEach((button) => {
        button.classList.add(self.config.buttonClassActive);
      });
    }
    this.enableTabTrap();
  }

  hide() {
    const self = this;
    document.documentElement.dataset[self.config.dataAttrName] = 'inactive';
    if (self.config.buttons) {
      self.config.buttons.forEach((button) => {
        button.classList.remove(self.config.buttonClassActive);
      });
    }
    this.disableTabTrap();
  }

  toggle() {
    const self = this;
    if (document.documentElement.dataset[self.config.dataAttrName] !== 'active') {
      self.show();
    } else {
      self.hide();
    }
  }

  initButtons() {
    const self = this;
    if (self.config.buttons) {
      for (let button of self.config.buttons) {
        button.addEventListener('click', () => {
          self.toggle();
        });
      }
    }
  }

  initInertElements() {
    const self = this;

    self.config.inertElements = document.querySelectorAll('#main-view, body > .sr-only-focusable, .cookie-notice');

    if (!self.config.inertElements) return;

    let mm = window.matchMedia(`(min-width: ${self.config.breakpointWidth})`);

    mm.addEventListener(
      'change',
      () => {
        if (mm.matches) self.hide();
      },
      false,
    );
  }

  enableTabTrap() {
    const self = this;

    if (!self.config.inertElements) return;

    for (const element of self.config.inertElements) {
      if (element) {
        element.inert = true;
      }
    }
  }

  disableTabTrap() {
    const self = this;

    if (!self.config.inertElements) return;

    for (const element of self.config.inertElements) {
      if (element) {
        element.inert = false;
      }
    }
  }

  initDesktopDropdowns() {
    const self = this;

    const langBtn = self.config.el.querySelector(`.c-hd-desktop__languages-current`);
    const langContainer = self.config.el.querySelector(`.c-hd-desktop__languages`);

    if (langBtn && langContainer) {
      langBtn.addEventListener('click', (e) => {
        e.preventDefault();
        langContainer.classList.toggle('is-active');
      });
    }

    const nav = self.config.el.querySelector('.c-hd-desktop__nav');

    if (nav) {
      const links = nav.querySelectorAll('.menu-item');

      links.forEach((link) => {
        link.addEventListener('mouseenter', () => {
          link.focus();
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        });
      });
    }

    document.addEventListener('click', (e) => {
      const nav = e.target?.closest('.c-hd-desktop__nav');
      if (!nav) {
        self.config.el.setAttribute('data-show-dropdown', 'false');
      }
      const container = e.target?.closest('.c-hd-desktop__languages');
      if (!container) {
        langContainer?.classList.remove('is-active');
      }
    });
  }

  initMobileDropdowns() {
    const self = this;

    const langBtn = self.config.el.querySelector(`.c-hd-mobile__languages-current`);
    const langContainer = self.config.el.querySelector(`.c-hd-mobile__languages`);

    if (langBtn && langContainer) {
      langBtn.addEventListener('click', (e) => {
        e.preventDefault();
        langContainer.classList.toggle('is-active');
      });
    }

    document.addEventListener('click', (e) => {
      const nav = e.target?.closest('.c-hd-mobile__nav');
      if (!nav) {
        self.config.el.setAttribute('data-show-dropdown', 'false');
      }
      const container = e.target?.closest('.c-hd-mobile__languages');
      if (!container) {
        langContainer?.classList.remove('is-active');
      }
    });
  }

  initDesktopSubmenus() {
    const self = this;

    const nav = self.config.el.querySelector('.c-hd-desktop__nav');

    if (nav) {
      const submenus = nav.querySelectorAll('.menu-item > .submenu');

      submenus.forEach((submenu) => {
        const links = submenu.querySelectorAll('.js-last-hovered > .submenu-item-link');

        links.forEach((link) => {
          link.addEventListener('mouseenter', () => {
            links.forEach((l) => {
              l.parentElement.classList.toggle('is-hovered', l === link);
            });
          });
          link.addEventListener('focus', () => {
            links.forEach((l) => {
              l.parentElement.classList.toggle('is-hovered', l === link);
            });
          });
        });

        links[0]?.parentElement?.classList.add('is-hovered');
      });
    }
  }
}
